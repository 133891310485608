<script>
import Spinner from "vue-simple-spinner";

import { getBeneficiary, createBeneficiary } from "@/state/helpers";
import {
  FETCH_BENEFICIARY_TABLE,
  FETCH_BENEFICIARY_TABLE_MASTER_MERCHANT,
  FETCH_MERCHANTS_LIST
} from "@/state/actions.type";
import Layout from "../../layouts/main";
import Multiselect from "vue-multiselect";

import Breadcrumb from '../../../components/breadcrumb.vue';
import SearchBy from '../../../components/search-by.vue';
import PerPage from '../../../components/per-page.vue';
import Filters from '../../../components/filters.vue';
import * as moment from "moment";

export default {
  components: {
    Layout,
    Spinner,
    Multiselect,
    Breadcrumb,
    SearchBy,
    PerPage,
    Filters
},

  data() {
    return {
      searchBy: {
        key: "Merchant Order Ref",
        value: ""
      },
      viewDetailsModalOpen:false,
      viewDetailsData:{},
      mor_filter: "",
      typesubmit: false,
      bankno: "",
      merchants: [],
      fields: [
        { key: "created_at", sortable: true, label: "Added Date", tooltip: "Added Date" },
        { key: "name", sortable: true, tooltip: "views.beneficiary.table.fields.beneficiary_name", label: "views.beneficiary.table.fields.name" },
        {
          key: "bank_accounts.account_number",
          sortable: true,
          label: "views.beneficiary.table.fields.bank_account_number",
          tooltip: "views.beneficiary.table.fields.bank_account_number",
        },
        {
          key: "email",
          sortable: true,
          label: "views.beneficiary.table.fields.email",
          tooltip: "views.beneficiary.table.fields.email",
        },
        {
          key: "phone",
          sortable: true,
          label: "views.beneficiary.table.fields.phone",
          tooltip: "views.beneficiary.table.fields.phone",
        },
        {
          key: "bank_accounts.bank_name",
          sortable: true,
          label: "views.payouts.table.fields.bank_name",
          tooltip: "views.payouts.table.fields.bank_name",
        },
        {
          key: "bank_accounts.account_name",
          sortable: true,
          label: "views.beneficiary.table.fields.bank-acc-name",
          tooltip: "views.beneficiary.table.fields.bank-acc-name",
        },
        {
          key: "bank_accounts.bank_no",
          sortable: true,
          label: "views.beneficiary.table.fields.bank-code",
          tooltip: "views.beneficiary.table.fields.bank-code",
        },
        {
          key: "address",
          sortable: true,
          label: "views.beneficiary.table.fields.address",
          tooltip: "views.beneficiary.table.fields.address",
        },
        {
          key: "action",
          label: "",
          sortable: true,
          stickyColumn: true,
        },
      ],
      from: "",
      to: "",
      fromDate: "",
      toDate: "",
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 25, 50, 100],
    };
  },
  filters: {
    date(value) {
        return moment(value).format("DD MMM YYYY");
    },
    time(value) {
        return 'at ' + moment(value).format("hh:mm A");
    },
    dateTime(value) {
      return moment(value).format("Do MMMM YYYY, h:mm a");
    }
  },
  created() {
    if(this.isMasterMerchant){
      this.$store.dispatch(`masterMerchant/${FETCH_MERCHANTS_LIST}`, {
        pageIndex: 1,
        pagesize: 10,
        filters: { source: "default" },
      })
      // this.fields = this.fields.map((field) => {
      //   if(field.key === 'action') {
      //     field.thClass = 'd-none';
      //     field.tdClass = 'd-none';
      //   }
      //   return field;
      // });
    }
  },

  computed: {
    items() {
      return [
        {
          text: this.$t("menuitems.payouts.list.payouts"),
          to: { name: 'Payouts' }
        },
        {
          text: this.$t("menuitems.payouts.list.beneficiary"),
          active: true,
        },
      ];
    },

    getBeneficiaryData() {
      return this.$store.state.beneficiary.content;
    },

    isFetchingTransactions() {
      return this.$store.state.beneficiary.isFetchingData;
    },

    rows() {
      return this.$store.state.beneficiary.total_elements;
    },
    isMasterMerchant() {
      return this.$store.state.auth.currentUser.is_master_merchant;
    },

    merchantListDataFromStore() {
      return this.$store.state.masterMerchant.content;
    },
  },

  methods: {
    ...getBeneficiary,
    ...createBeneficiary,

    loadData() {
      if(this.toDate) {
        this.to = moment(this.toDate, 'DD-MM-YYYY HH:mm').toISOString();
      }else{
        this.to = new Date().toISOString()
      }

      if(!this.fromDate) {
        this.from = moment('2018-01-01T00:00:00.000').toISOString();
      }else{
        this.from = moment(this.fromDate, 'DD-MM-YYYY HH:mm').toISOString();
      }

      let payload = {
        page: this.currentPage,
        pageIndex: this.currentPage,
        pagesize: this.perPage,
        from: this.from,
        to: this.to,
      }

      if(this.isMasterMerchant) {
        payload.filters = {
          merchants: this.merchants.map(merchant => merchant.email_id),
        }

        if(this.searchBy.value) {
          payload.filters.common = this.searchBy.value;
        }

        this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE_MASTER_MERCHANT}`, payload);
      } else {

        if(this.searchBy.value) {
          payload.filter = this.searchBy.value;
        }

        this.$store.dispatch(`beneficiary/${FETCH_BENEFICIARY_TABLE}`, payload);
      }
    },

    editBeneficiary(item) {
      this.$router.push({ name: 'Update Beneficiary', query: { ref: item.uuid } })
    },

    filterTransactionByMor() {
      if (this.searchBy.value == "") {
        this.loadData();
      } else {
        this.loadData();
      }
    },

    fetchTransactionOnEmptyFilter() {
      if (this.searchBy.value == "") {
        this.loadData();
      }
    },

    merchantSelected() {
      this.loadData();
    },

    closeViewDetailsModal() {
      this.viewDetailsModalOpen = false;
    },
    viewDetailsClick(data){
      this.viewDetailsData = data
      this.viewDetailsModalOpen = true
    },
    dateChanged(value) {
      this.fromDate = value[0];
      this.toDate = value[1];

      this.loadData();
    },
  },

  beforeDestroy() {
    this.$store.dispatch("notification/clear");
  },
};
</script>

<template>
  <Layout>
    <div class="d-flex justify-content-between">
      <breadcrumb :items="items" :goBackTo="{ name: 'Payouts' }" />
      <router-link :to="'/payouts/vendors/create-beneficiary'">
        <b-btn style="height: 44px;padding: 0px 16px;" v-if="!isMasterMerchant" variant="primary">
          <span style="padding-right: 10px;">+</span>{{ $t("views.payouts.create-beneficiary") }}
        </b-btn>
      </router-link>
  </div>

    <div class="row">
      <div class="col-lg-12">
        <multiselect
          v-if="isMasterMerchant"
          v-model="merchants"
          :options="merchantListDataFromStore"
          @input="merchantSelected"
          placeholder="Search Merchants"
          track-by="email_id"
          label="email_id"
          class="sm-multiselect col-md-3 ml-auto"
          :searchable="true"
          :multiple="true"
          :taggable="true"
        ></multiselect>
        <div class="row mt-4 d-flex justify-content-between">
            <div class="col-sm-12 col-md-12">
               <filters class="mb-3" @date-change="dateChanged" hideFilter />
            </div>
        </div>
        <div class="d-flex justify-content-between mb-3">
          <!-- :options="searchByOptions" -->
          <search-by v-model="searchBy" @search-by-clicked="loadData" :hideSearchButton="true" />
        </div>
        <!-- <div class="d-flex flex-row justify-content-between my-4">
          <div class="d-flex justify-content-end w-50">
            <div class="flex-fill mr-2">
              <b-input-group>
                <b-form-input
                  type="search"
                  v-model="mor_filter"
                  @keyup="loadData"
                  @keyup.enter="loadData"
                  class="form-control-sm"
                  placeholder="Search for references or customer details"
                ></b-form-input>
                <b-input-group-append>
                  <button
                    class="btn btn-primary btn-block btn-sm"
                    @click="loadData"
                  >
                    {{ $t("button.search") }}
                  </button>
                </b-input-group-append>
              </b-input-group>
            </div>
          </div>
          <div class="col-sm-12 col-md-4" style="display: none">
            <b-input-group>
              <b-form-input
                type="search"
                v-model="mor_filter"
                @keyup="fetchTransactionOnEmptyFilter"
                @keyup.enter="filterTransactionByMor"
                class="form-control form-control-sm ml-2"
                :placeholder="$t('views.payouts.search_vendors')"
              ></b-form-input>
              <b-input-group-append>
                <button
                  class="btn btn-primary btn-block btn-sm"
                  @click="filterTransactionByMor"
                >
                  {{ $t("button.search") }}
                </button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </div> -->

        <div class="">
          <b-table
            id="my-table"
            class="table-centered text-left table-custom"
            :items="getBeneficiaryData"
            :fields="fields"
            responsive="lg"
            :per-page="perPage"
            :bordered="false"
            sticky-header="500px"
            show-empty
            :empty-text="$t('common.no-records')"
            busy.sync="true"
            hover
          >

            <template #cell(created_at)="row">
                <p class="table-date">{{ row.value | date }}</p>
                <p class="table-time">{{ row.value | time }}</p>
            </template>
            <template #cell(account_number)="data">
              <router-link
                :to="'/payouts/vendor-accounts?uuid=' + data.item.uuid"
                class="card-link text-decoration-underline"
              >
                {{ $t("views.payouts.view-details") }}
              </router-link>
            </template>

            <template v-slot:head()="data">
              <span v-b-tooltip.hover.top :title="$t(data.field.tooltip)">
                {{ $t(data.label) }}
              </span>
            </template>
            
            <template v-slot:cell(action)="{ item }">
              <div style="display: flex;align-items: center;gap: 10px; cursor: pointer;">
                  <div v-if="!isMasterMerchant"  @click="editBeneficiary(item)" style="padding: 10px 10px;border-radius: 8px;background: rgba(220, 218, 213, 0.30); height: 36px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                          <g id="vuesax/linear/edit-2" opacity="0.3">
                            <g id="edit-2">
                            <path id="Vector" d="M8.83958 2.39982L3.36624 8.19315C3.15958 8.41315 2.95958 8.84649 2.91958 9.14649L2.67291 11.3065C2.58624 12.0865 3.14624 12.6198 3.91958 12.4865L6.06624 12.1198C6.36624 12.0665 6.78624 11.8465 6.99291 11.6198L12.4662 5.82649C13.4129 4.82649 13.8396 3.68649 12.3662 2.29315C10.8996 0.913152 9.78624 1.39982 8.83958 2.39982Z" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path id="Vector_2" d="M7.92578 3.3667C8.21245 5.2067 9.70578 6.61337 11.5591 6.80003" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            <path id="Vector_3" d="M2 14.6665H14" stroke="black" stroke-width="1.5" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                            </g>
                          </g>
                        </svg>
                  </div>
                  <div @click="viewDetailsClick(item)" style="padding: 10px 10px;border-radius: 8px;background: rgba(220, 218, 213, 0.30); height: 36px;">
                      <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                        <g id="Iconsax/Linear/arrowright2" opacity="0.3">
                        <path id="Vector" d="M5.93945 13.2802L10.2861 8.93355C10.7995 8.42021 10.7995 7.58021 10.2861 7.06688L5.93945 2.72021" stroke="black" stroke-width="2" stroke-miterlimit="10" stroke-linecap="round" stroke-linejoin="round"/>
                        </g>
                      </svg>
                  </div>
              </div>
          </template>

          </b-table>
          <Spinner v-if="isFetchingTransactions === true"></Spinner>
          <div class="d-flex justify-content-between mb-3">
            <!-- pagination -->
              <per-page
                v-model="perPage"
                :currentPage="currentPage"
                :totalRows="rows"
                :tableRowsCount="getBeneficiaryData.length"
                @input="loadData"
              />
              <b-pagination v-model="currentPage" :current-page="currentPage" :total-rows="rows" :per-page="perPage" @input="loadData" ></b-pagination>
          </div>
        </div>
      </div>
    </div>
    <div
      class="right-bar"
      v-if="viewDetailsModalOpen"
      :class="{
        active: viewDetailsModalOpen,
      }"
    >
      <div data-simplebar class="h-100" style="overflow-x: auto">
        <div class="rightbar-title">
          <div class="right-top-section">
            <div class="card-body-custom">
              <i
                class="mdi mdi-close noti-icon float-right cursor-pointer"
                style="
                  position: relative;
                  z-index: 1;
                  right: 25px;
                  top: 25px;
                  font-size: 20px;
                "
                @click="closeViewDetailsModal"
              ></i>
              <div style="padding-left: 30px; padding-bottom: 25px; padding-right: 30px;" class="media-body media-body-custom">
                <p
                  class="d-inline-block"
                  style="font-size: 18px; margin-top: 45px; margin-bottom: 0px; line-height: 24px;"
                >
                  Beneficiary Details
                </p>
                <div style="color: rgba(0, 0, 0, 0.50);font-size: 12px;font-weight: 325;line-height: 16px;margin-top: 2px;">Check details of the selected payout.</div>
              </div>
              <div style="border-top: 1px solid rgba(220, 218, 213, 0.5); margin-bottom: 25px;"></div>
            </div>
          </div>
        </div>
        <div class="plan-view-details" style="padding-left: 30px;padding-right: 30px;">
          <!-- {{viewDetailsData}} -->
          <div class="plan-view-section">
            <div class="plan-view-heading">Added On</div>
            <div class="plan-view-value">{{viewDetailsData.created_at | dateTime}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Beneficiary Name</div>
            <div class="plan-view-value text-chai">{{viewDetailsData.name}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Contact Number</div>
            <div class="plan-view-value">{{viewDetailsData.phone}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Selected PSP</div>
            <div class="plan-view-value">{{viewDetailsData.payout_channel}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Address</div>
            <div class="plan-view-value">{{viewDetailsData.address}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Bank Name</div>
            <div class="plan-view-value">{{viewDetailsData.bank_accounts.bank_name}}</div>
          </div>
          <div class="plan-view-section">
            <div class="plan-view-heading">Bank Account Number</div>
            <div class="plan-view-value">{{viewDetailsData.bank_accounts.account_number}}</div>
          </div>
                    <div class="plan-view-section">
            <div class="plan-view-heading">Account Name</div>
            <div class="plan-view-value">{{viewDetailsData.bank_accounts.account_name}}</div>
          </div>
                    <div class="plan-view-section">
            <div class="plan-view-heading">Bank Code</div>
            <div class="plan-view-value">{{viewDetailsData.bank_accounts.bank_no}}</div>
          </div>
        </div>
      </div>
      <!-- end slimscroll-menu-->
    </div>
    <!-- Right bar overlay-->
    <div
      @click="closeViewDetailsModal"
      class="rightbar-overlay"
      :class="{
        active: viewDetailsModalOpen,
      }"
    ></div>
  </Layout>
</template>

<style lang="css" scoped>
::v-deep .b-table-sticky-column:last-child {
  right: 0;
}
:disabled {
  background: #f3f6f9;
  color: #9da0af;
}
.form-control{
  font-size: 14px;
}
label{
  font-size: 14px;
}
.right-top-section{
  position: relative;
  z-index: 1;
}
.right-bar{
  width: 500px;
  right: -500px;
}
.right-bar.active{
  right: 0px;
}
.rightbar-overlay.active{
  display: block;
  position: fixed;
}
.rightbar-title{
  color: #252B3B;
}
.right-bar .form-control,.right-bar .custom-multiselect .multiselect__tags{
  border: 1px solid #00246414;
}
.right-top-bg-image{
    position: absolute;
    right: 0px;
}
.right-bottom-bg-image{
  position: absolute;
  bottom: 0px;
}

.cursor-pointer{
  cursor: pointer;
}
@media (max-width: 700px) {
  .right-bar{
    width: 100%;
    right: -100%;
  }
}
@media (max-width: 539px) {
  .right-bar{
    width: 320px;
    right: -320px;
  }
}
.plan-view-section{
  margin-bottom: 16px;
}
.plan-view-section .plan-view-heading{
    font-size: 14px;
    color: rgba(0, 0, 0, 0.30);
    line-height: 20px;
}
.plan-view-section .plan-view-value{
    font-size: 14px;
    line-height: 20px;
    padding-top: 4px;
}
</style>